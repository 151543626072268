import React from 'react'

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Client:</span>
                        <p>Bancă digitală</p>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Locație:</span>
                        Dubai, UAE
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Tehnologii:</span>
                        Java, Spring, MySQL, AWS, Angular, Kibana, Jira
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Finalizat:</span>
                        Mai 2023
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-calendar'></i>
                        </div>
                        <span>Durată contract:</span>
                        <p>18 luni</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CaseStudiesSidebar