import React from 'react'
import CaseStudiesSidebar from './CaseStudiesSidebar'
import details1 from '../../../assets/images/projects/projects-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Echipă dedicată</span>
                            <h3>Compania</h3>
                            <p>O companie din domeniul financiar bancar din Arabia Saudită ne-a solicitat crearea unei aplicații mobile de banking care deservea la efectuarea de plăti online.</p>              
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>
                            </div>
                            <h3>Provocarea</h3>
                            <p>Ca întotdeauna la început de proiect nou, am discutat în detaliu cu reprezentanții companiei și am analizat toate cerințele și necesitățile, am întocmit 
                                caietul de sarcini și am ajuns la concluzia că aplicația mobilă trebuie să ajute la transferul de bani rapid și fără comisioane între utilizatorii aplicației.
                                </p>
                            <div className="col-lg-12 col-md-12">
                                    <div className="content">
                                        <h3>Soluția</h3>
                                        <p>Pe baza acestor informații, Vertical Digital a început să dezvolte o aplicație mobilă dorită de client. Am folosit o combinație de tehnologii:</p>
                                        <ul>
                                            <li>Java</li>
                                            <li>Spring</li>
                                            <li>SQL</li>
                                            <li>Kibana</li>
                                            <li>AWS</li>
                                        </ul>
                                    </div>
                                </div>
                            <p>Din cadrul companiei noastre au fost alocați nouă specialiști pentru dezvoltarea acestui produs, care au lucrat timp de 18 luni la proiect: 8 ingineri Java.</p>
                            <p>Acest proiect ne-a apropiat mult ca echipă, pentru că am lucrat îndeaproape și am conlucrat la rezolvarea tuturor task-urilor și problemelor apărute pe parcurs.
                                 Am avut o excelentă cooperare cu cei din echipa de management de proiect al partenerilor noștri, cu care analizam pe parcurs toate funcționalitățile aplicației.
                                 De asemenea, am cooperat cu YAP operation pentru rezolvarea problemelor tehnice întâmpinate de clienți și am reușit să îmbunătățim mult code base-ul aplicației.
                                 </p>
                            <p>După 18 luni, clientul nostru s-a bucurat să aibă aplicația mobilă dorită funcțională și să-și poată dezvolta activitatea.</p>
                            <p>Prin urmare, prin aplicația mobilă creată de la 0 de Vertical Digital, compania a pus la dispoziția clienților săi următoarele funcționalități:</p>
                            <div className="content">
                                        <ul>
                                            <li>logare securizată prin parolă/ pin / cod OTP </li>
                                            <li>transfer de bani între utilizatori</li>
                                            <li>plăți diverse </li>  
                                        </ul>
                                    </div>
                            <h3>Rezultate</h3>
                            <p>Am verificat și testat aplicația și toate funcționalitățile ei, pentru a fi siguri că nu există absolut nicio eroare de funcționare.</p>
                            <p>Pentru partenerii noștri, această aplicație a adus 30.000 de utilizatori în primele 6 luni de la lansare.</p>
                            <p>În concluzie, aplicația Vertical Digital a adus un mare plus în funcționarea modernă și rapidă a business-ului clienților noștri.</p>
                            <h3>Opinii</h3>
                            <p>” Perioada petrecută în cadrul acestui proiect a însemnat un real progres în carierea mea de developer. Pentru mine a fost atât o provocare
                                 cât și o oportunitate prin care mi-am putut îmbunătăți cunoștințele. Pe partea de suport am acumulat mai multe cunoștințe de SQL și Kibana,
                                  iar pe partea de development mi-am dezvoltat cunoștințele de Java/Spring. A fost o reală plăcere să fac parte din această echipă, deoarece
                                   cred că principalul motiv al rezultatelor bune obținute în acest proiect a fost comunicarea în echipă și buna organizare.", spune Alex V.,
                                    Vertical Digital</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContent